import slugify from 'slugify'
import { Flat, FlatCRM, LocalCRM } from 'types/domain'
import convertToArabic from './convertToArabic'

export const parseImage2D = (number: string, stage: string | null) =>
  `https://cmsmiasteczko.indigital.guru/flats/${
    stage
      ? `Etap_${convertToArabic(stage.split(' ')[1].trim())}`
      : 'Dzielnica_Lawendy'
  }/PNG/${number ? number.replace('/', '_') : ''}.png`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const parseImage3D = (number: string, stage: string | null) => ``

export const parseStatusId = (statusID: number) => {
  switch (statusID) {
    case 1:
    case 2:
      return 1
    case 11:
    case 12:
      return 2
    case 3:
    case 6:
    case 10:
    case 13:
    case 14:
    case 15:
    case 16:
      return 3

    default:
      // eslint-disable-next-line no-console
      console.error(`Unknown status: ${statusID}`)
      return statusID
  }
}

const parseStatus = (statusID: number) => {
  switch (statusID) {
    case 1:
    case 2:
      return 'available' as const
    case 11:
    case 12:
      return 'reserved' as const
    case 3:
    case 6:
    case 10:
    case 13:
    case 14:
    case 15:
    case 16:
      return 'sold' as const

    default:
      // eslint-disable-next-line no-console
      console.error(`Unknown status: ${statusID}`)
      return 'sold'
  }
}

export const getStatusName = (status: Flat['status']) => {
  switch (status) {
    case 'available':
      return 'Dostępne' as const
    case 'reserved':
      return 'Zarezerwowane' as const
    case 'sold':
      return 'Sprzedane' as const

    default:
      return 'Nieznany' as const
  }
}

export const parseFlat = (flat: FlatCRM) => {
  let stage = null
  if (flat.stage2) {
    stage = flat.stage2.replace('MJ', '').trim()
    stage = `${stage[0].toUpperCase()}${stage.slice(1)}`
  }
  const { number } = flat
  const building = flat.building.replace('04_', '').replace('05_', '')
  const investment = flat.subdivision2

  return {
    id: stage ? `${number}-${slugify(stage, { lower: true })}` : `${number}`,
    urlID: String(flat.localId),
    buildingName: building,
    floor: flat.floor,
    fullNumber: `${investment} ${number}`,
    investmentName: investment,
    investmentNameWithStage: flat.subdivision,
    localSurface: flat.area,
    number,
    rooms: flat.rooms,
    area: flat.area,
    stageName: stage,
    statusId: parseStatusId(flat.statusId),
    status: parseStatus(flat.statusId),
    typeName: flat.localType,
    img2D: parseImage2D(number, stage),
    img3D: parseImage3D(number, stage),
    pdf: flat.projections_uri || null,
  }
}

export const parseLocal = (local: LocalCRM) => {
  let stage = null
  if (local.stage2) {
    stage = local.stage2.replace('MJ', '').trim()
    stage = `${stage[0].toUpperCase()}${stage.slice(1)}`
  }
  const { number } = local
  const building = local.building.replace('04_', '').replace('05_', '')
  const investment = local.subdivision2

  return {
    id: stage ? `${number}-${slugify(stage, { lower: true })}` : `${number}`,
    urlID: local.localId ? String(local.localId) : number,
    buildingName: building,
    floor: local.floor,
    fullNumber: `${investment} ${number}`,
    investmentName: investment,
    investmentNameWithStage: local.subdivision,
    localSurface: local.area,
    area: local.area,
    number,
    rooms: local.rooms,
    stageName: stage,
    statusId: parseStatusId(local.statusId),
    status: parseStatus(local.statusId),
    typeName: local.localType,
    img2D: parseImage2D(number, stage),
    img3D: parseImage3D(number, stage),
    pdf: local.projections_uri || null,
  }
}

export const parseFlats = (flats: FlatCRM[]) => flats.map(parseFlat)
export const parseLocals = (locals: LocalCRM[]) => locals.map(parseLocal)
