import { css, createGlobalStyle } from 'styled-components'

import Normalize from 'styles/normalize'

const Global = css`
  * {
    outline-color: ${({ theme }) => theme.colors.primary};
  }

  main {
    overflow: hidden;
  }

  html {
    font-size: ${({ theme }) => theme.fonts.size};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  body {
    font-family: Karla, sans-serif;
  }

  span.text-green {
    color: #3da544;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0px;
    padding: 0px;
    font-family: 'Kumbh Sans Variable', sans-serif;
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`

const GlobalStyles = createGlobalStyle`
    ${Normalize}
    ${Global} 
`

export default GlobalStyles
