import type { Breakpoints, Colors, Fonts } from 'types/theme'

export const breakpoints: Breakpoints = {
  base: 0,
  xs: 400,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  '3xl': 1600,
}

export const colors: Colors = {
  green100: '#319339',
  primary: '#3DA544',
  green20: '#46B44D',
  green10: '#A4D9A7',
  green05: '#C8E8C9',
  green0: '#E8F6E9',

  alert100: '#9F1B1F',
  alert50: '#DE1C22F',
  alert05: '#DE1C22',

  warning100: '#A64F21',
  warning50: '#E86825',
  warning05: '#FFE1BE',

  success100: '#116942',
  success50: '#1BA769',
  success05: '#DEF2E9',

  sold: '#de1c22',
  available: '#1ba769',
  reserved: '#f9ad17',

  textPrimary: '#242424',

  white: '#FFFFFF',
  black: '#000000',

  secondary: '#242424',
  gray60: '#8B8B8B',
  gray50: '#454545',
  gray20: '#656565',
  gray10: '#A2A2A2',
  gray05: '#E3E3E3',
  gray0: '#FBFBFB',

  whiteOpacity72: 'rgba(255, 255, 255, 0.72)',
  whiteOpacity40: 'rgba(255, 255, 255, 0.4)',
  whiteOpacity12: 'rgba(255, 255, 255, 0.12)',
}

export const fonts: Fonts = {
  size: '16px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
}

export default theme
