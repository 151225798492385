/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
require('@fontsource-variable/kumbh-sans')
require('@fontsource/roboto')

const React = require('react')
const { ThemeProvider } = require('styled-components')

const { default: theme } = require('styles/theme')
const { default: GlobalStyles } = require('styles/global')
const { default: FlatContextProvider } = require('contexts/FlatContext')

const $ = require('jquery')

if (typeof window !== 'undefined') {
  window.jQuery = $
  window.$ = $
}

exports.wrapRootElement = ({ element }) => {
  return (
    <FlatContextProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {element}
      </ThemeProvider>
    </FlatContextProvider>
  )
}

exports.onRouteUpdate = ({ prevLocation }) => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = window.locations[window.locations.length - 2]

  /* eslint-disable */
  if (prevLocation) {
    setTimeout(() => {
      ylData = window.ylData = window.ylData || []
      ylData.push({
        page: {},
      })
    }, 250)
  }
  /* eslint-enable */
}
