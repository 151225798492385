import React, {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import localforage from 'localforage'

import { Flat, Local } from 'types/domain'
import { parseFlats, parseLocals } from '../utils/parseFlat'

const STORAGE_ITEM_NAME = `favourites-miasteczko-jutrzenki`

type FlatContextProps = {
  promotionFlatsWithPrice: Array<{
    flatId: string
    flatPrice: number
  }>
  flats: Flat[]
  availableFlats: Flat[]
  locals: Local[]
  favourites: Flat[]
  addToFavourites: (flat: Flat) => void
  removeFromFavourites: (flat: Flat) => void
  toggleFavourite: (flat: Flat) => void
  isFavourite: (flat: Flat) => boolean
}

export const FlatContext = createContext<FlatContextProps>({
  promotionFlatsWithPrice: [],
  flats: [],
  availableFlats: [],
  locals: [],
  favourites: [],
  addToFavourites: () => {},
  removeFromFavourites: () => {},
  toggleFavourite: () => {},
  isFavourite: () => false,
})

const FlatContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [favouritesIds, setFavouritesIds] = useState<Flat['id'][]>([])

  const data = useStaticQuery<Queries.FlatContextQuery>(graphql`
    query FlatContext {
      crm {
        flats: locals(
          subdivision2: "Miasteczko Jutrzenki"
          localType: "mieszkanie"
          stage2: ["etap IV", "etap V", null]
        ) {
          localId
          subdivision2
          subdivision
          statusId
          rooms
          stage2
          number
          name
          localType
          floor
          externalNumber
          buildingId
          building
          area
          projections_uri
        }
        locals: locals(
          subdivision2: "Miasteczko Jutrzenki"
          localType: "lokal użytkowy"
          stage2: ["etap IV", "etap V", null]
        ) {
          localId
          subdivision2
          subdivision
          statusId
          rooms
          stage2
          number
          name
          localType
          floor
          externalNumber
          buildingId
          building
          area
          projections_uri
        }
      }
      allWpPromotionsSingle {
        nodes {
          promotionSingle {
            promotionSingleFlatsListList {
              promotionSingleFlatsListFlatPrice
              promotionSingleFlatsListListFlatId
            }
          }
        }
      }
    }
  `)

  const promotionFlatsWithPrice = data.allWpPromotionsSingle.nodes
    .map((node) => {
      const flats =
        node?.promotionSingle?.promotionSingleFlatsListList?.filter(
          (flat) => flat?.promotionSingleFlatsListFlatPrice !== null
        ) || []
      return flats?.map((flat) => ({
        flatId: flat?.promotionSingleFlatsListListFlatId!,
        flatPrice: flat?.promotionSingleFlatsListFlatPrice!,
      }))
    })
    .flat()

  const flats = useMemo(
    () =>
      parseFlats(
        // @ts-ignore
        (data?.crm?.flats ?? []).filter(
          (flat) =>
            flat &&
            // ['etap IV', 'etap V'].includes(flat.stage2 ?? '')  ||
            ['106D', '106C', '106E'].includes(flat.building ?? '')
        )
      ),
    [data.crm.flats]
  )
  const locals = useMemo(
    () =>
      parseLocals(
        // @ts-ignore
        (data?.crm?.locals ?? []).filter(
          (flat) =>
            flat &&
            // ['etap IV', 'etap V'].includes(flat.stage2 ?? '') ||
            ['106D', '106C', '106E'].includes(flat.building ?? '')
        )
      ),
    [data.crm.locals]
  )
  const favourites = useMemo(
    () => flats.filter((flat) => favouritesIds.includes(flat.id)),
    [flats, favouritesIds]
  )
  const availableFlats = useMemo(
    () => flats.filter((flat) => flat.status !== 'sold'),
    [flats]
  )

  const updateFavourites = (newFavs: Flat['id'][]) => {
    setFavouritesIds(newFavs)
    localforage.setItem(STORAGE_ITEM_NAME, newFavs)
  }

  const addToFavourites = (flat: Flat) => {
    const newFavs = [...favouritesIds, flat.id]
    updateFavourites(newFavs)

    // emitGTMEvent({
    //   event: "add_to_favourites",
    // })
  }

  const removeFromFavourites = (flat: Flat) => {
    updateFavourites(favouritesIds.filter((id) => id !== flat.id))
  }

  const isFavourite = (flat: Flat) => {
    return favourites.some((fav) => fav.id === flat.id)
  }

  const toggleFavourite = (flat: Flat) =>
    isFavourite(flat) ? removeFromFavourites(flat) : addToFavourites(flat)

  const getFavourites = () => {
    localforage
      .getItem<Flat['id'][] | null>(STORAGE_ITEM_NAME)
      .then((value) => {
        if (value && Array.isArray(value)) {
          setFavouritesIds(value)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getFavourites()
  }, [])

  const value = useMemo(
    () => ({
      promotionFlatsWithPrice,
      favourites,
      flats,
      availableFlats,
      locals,
      addToFavourites,
      removeFromFavourites,
      toggleFavourite,
      isFavourite,
    }),
    [favourites, flats, locals]
  )

  return <FlatContext.Provider value={value}>{children}</FlatContext.Provider>
}

export default FlatContextProvider
