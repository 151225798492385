exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktualnosci-tsx": () => import("./../../../src/pages/aktualnosci.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tsx" */),
  "component---src-pages-deweloper-tsx": () => import("./../../../src/pages/deweloper.tsx" /* webpackChunkName: "component---src-pages-deweloper-tsx" */),
  "component---src-pages-dzielnica-lawendy-tsx": () => import("./../../../src/pages/dzielnica-lawendy.tsx" /* webpackChunkName: "component---src-pages-dzielnica-lawendy-tsx" */),
  "component---src-pages-dziennik-budowy-tsx": () => import("./../../../src/pages/dziennik-budowy.tsx" /* webpackChunkName: "component---src-pages-dziennik-budowy-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-lokalizacja-tsx": () => import("./../../../src/pages/lokalizacja.tsx" /* webpackChunkName: "component---src-pages-lokalizacja-tsx" */),
  "component---src-pages-miasteczko-jutrzenki-tsx": () => import("./../../../src/pages/miasteczko-jutrzenki.tsx" /* webpackChunkName: "component---src-pages-miasteczko-jutrzenki-tsx" */),
  "component---src-pages-oferty-specjalne-tsx": () => import("./../../../src/pages/oferty-specjalne.tsx" /* webpackChunkName: "component---src-pages-oferty-specjalne-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-ulubione-tsx": () => import("./../../../src/pages/ulubione.tsx" /* webpackChunkName: "component---src-pages-ulubione-tsx" */),
  "component---src-pages-wyszukiwarka-tsx": () => import("./../../../src/pages/wyszukiwarka.tsx" /* webpackChunkName: "component---src-pages-wyszukiwarka-tsx" */),
  "component---src-templates-flat-tsx": () => import("./../../../src/templates/flat.tsx" /* webpackChunkName: "component---src-templates-flat-tsx" */),
  "component---src-templates-log-entry-tsx": () => import("./../../../src/templates/log-entry.tsx" /* webpackChunkName: "component---src-templates-log-entry-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-sale-tsx": () => import("./../../../src/templates/sale.tsx" /* webpackChunkName: "component---src-templates-sale-tsx" */)
}

